<template>
  <div class="bigdatapay">
    <van-empty image="error" description="支付成功" />
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.bigdatapay{
  display: flex;
}
</style>
